<template>
  <div>
    <vs-card>
      <h4 class="mb-2 ml-5 mt-2">Create hubspot supplier/tracking setting</h4>
      <form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-w="6">
            <vs-col vs-type="flex" vs-w="12">
              <div class="w-full m-5">
                <label class="label" name="hubspot-label"
                  >Hubspot Field Code</label
                >
                <vs-input
                  :success="
                    !errors.first('basic.hubspotFieldCode') &&
                    hubspot.hubspotFieldCode != ''
                  "
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="
                    errors.first('basic.hubspotFieldCode') ? true : false
                  "
                  v-validate="'required'"
                  name="hubspotFieldCode"
                  data-vv-as="hubspotFieldCode"
                  class="w-full"
                  data-vv-scope="basic"
                  :danger-text="errors.first('basic.hubspotFieldCode')"
                  v-model="hubspot.hubspotFieldCode"
                />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-w="12">
              <div class="w-full m-5">
                <label class="label" name="selectedSupplier-label"
                  >Supplier</label
                >
                <v-select
                  :clearable="false"
                  label="name"
                  :options="suppliers"
                  name="selectedSupplier"
                  class="w-full"
                  v-model="hubspot.selectedSupplier"
                />
                <p
                  v-if="errorSelectedSupplier"
                  class="text-red-200"
                  style="color: red"
                >
                  Must select supplier
                </p>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-align="left" vs-w="6">
              <div class="w-full m-5">
                <vs-button
                  size="normal"
                  @click="saveData"
                  icon-pack="feather"
                  class="mr-2"
                  >Create Hubspot Setting</vs-button
                >
              </div>
            </vs-col>
          </vs-col>
        </vs-row>
      </form>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";

export default {
  name: "questionnaireCreate",
  props: {
    backRoute: {
      type: String,
      default: "ConsentQuestionnaire",
    },
  },
  components: {
    "v-select": vSelect,
  },
  data: () => ({
    hubspot: {
      hubspotFieldCode: "",
      selectedSupplier: "",
    },
    errorSelectedSupplier: false,
    suppliers: [],
  }),
  methods: {
    ...mapActions("hubspot", [
      "fetchHubspotSettings",
      "fetchHubspotSetting",
      "createHubspotSetting",
      "updateHubspotSetting",
    ]),
    ...mapActions("storeProducts", ["fetchSuppliers"]),

    getSuppliers() {
      this.fetchSuppliers().then((res) => {
        this.suppliers = res.data.data;
      });
    },
    getSetting(id) {
      this.fetchHubspotSetting(id).then((res) => {
        this.hubspot = {
          ...res.data.data[0],
          selectedSupplier: this.suppliers.filter(
            (item) => item._id == res.data.data[0].supplierCourier
          )[0],
        };
      });
    },
    async saveData() {
      let self = this;
      self.errorSelectedSupplier = false;
      let isValid = await this.$validator.validateAll("basic");
      await this.$validator.validate();
      if (self.hubspot.selectedSupplier == "")
        self.errorSelectedSupplier = true;

      if (isValid) {
        if (self.errorSelectedSupplier) return;
        const info = {
          ...self.hubspot,
          supplierCourier: self.hubspot.selectedSupplier._id,
          hubspotFieldCode: self.hubspot.hubspotFieldCode,
        };
        if (!this.$route.params.id) {
          await this.createHubspotSetting(info)
            .then((res) => {
              self.$vs.notify({
                title: "Setting Created",
                text: "Setting created Successfully.",
                color: "success",
              });
              self.$router.push("/super-admin/hubspot-webhook-settings");
            })
            .catch((err) => {
              self.$vs.notify({
                title: "Failed",
                text: "Failed to created setting. Try again!",
                color: "danger",
              });
              isValid = false;
              if (err.status === 422) {
                self.mapServerErrors(self, err, "basic");
              }
            });
        } else {
          await this.updateHubspotSetting(info)
            .then((res) => {
              self.$vs.notify({
                title: "Setting Updated",
                text: "Setting updated Successfully.",
                color: "success",
              });
              self.$router.push("/super-admin/hubspot-webhook-settings");
            })
            .catch((err) => {
              self.$vs.notify({
                title: "Failed",
                text: "Failed to updated setting. Try again!",
                color: "danger",
              });
              isValid = false;
              if (err.status === 422) {
                self.mapServerErrors(self, err, "basic");
              }
            });
        }
      }
    },
    mapServerErrors(self, err, form = "") {
      let data = err.data.data.details;
      data.map(function (value, key) {
        self.errors.add({
          field: value.context.key,
          msg: value.message.replace(/"/g, ""),
        });
      });
    },
  },
  mounted() {},
  created() {
    let pageId = this.$route.params.id;
    if (pageId) this.getSetting(pageId);
    this.getSuppliers();
  },
};
</script>
